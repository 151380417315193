import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IframeService {
  public IsInIframe: boolean;

  public readonly PEOPLE_POWER_SUBDOMAIN_PREFIXES: Array<string> = ['viewer', 'test', 'duck'];
  private readonly PEOPLE_POWER_DOMAIN: string = "peoplepower.nl";
  private readonly PEOPLE_POWER_DUCK_DOMAIN: string = "duck." + this.PEOPLE_POWER_DOMAIN;
  // Always run locally SB on this port.
  // If not possible change the port here.
  private readonly LOCALHOST_URL_PORT: string = "44344";

  constructor() {
    this.IsInIframe = this.isWindowInIFrame();
  }

  /// Return the referrer from webviewer app.
  public getWebViewerReferrer(): string {
    if (this.IsInIframe) {
      let supplier: string = (new URL(this.getLocationHref())).searchParams.get("supplier")?.toLowerCase();
      let hostname: string = new URL(document.referrer).hostname;

      if (hostname.startsWith("localhost")) {
        return `https://localhost:${this.LOCALHOST_URL_PORT}/${supplier}`;
      };

      if (this.PEOPLE_POWER_SUBDOMAIN_PREFIXES.some((subdomain) => hostname.startsWith(subdomain))) {
        window.sessionStorage.hostname = hostname;
      } else {
        hostname = window.sessionStorage.hostname;
      };

      if (hostname.endsWith(this.PEOPLE_POWER_DUCK_DOMAIN)) {
        let html: string = supplier.slice(0, -2);
        return `https://${hostname}/html-${html.substring(1, 3)}.${html.substring(3, 5)}.${html.substring(5)}/${supplier}`;
      };
      if (hostname.endsWith(this.PEOPLE_POWER_DOMAIN)) {
        return `https://${hostname}/html/${supplier}`;
      }
    }
    return null;
  }

  /**
   * Validating that external application are trused
   * @param origin url of the host that it's trying to communicate with ABSpace
   */
  public checkHostName(origin: string): boolean {
    let originHostname: string = new URL(origin).hostname;
    //for development purpose
    if (originHostname.startsWith("localhost")) {
      return true;
    }

    //we check build configuration of prefixes and domains
    return (originHostname.endsWith(this.PEOPLE_POWER_DUCK_DOMAIN) || originHostname.endsWith(this.PEOPLE_POWER_DOMAIN))
      && this.PEOPLE_POWER_SUBDOMAIN_PREFIXES.some((subdomain) => originHostname.startsWith(subdomain));
  }

  /// Returns the webviewer global language.
  public getWebViewerLanguage(): string {
    if (this.IsInIframe) {
      let language: string = (new URL(this.getLocationHref())).searchParams.get("lang")?.toLowerCase();
      return language;
    }
    return null;
  }

  /// Checks if the current application is in iframe.
  private isWindowInIFrame(): boolean {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  /// Helper for testing window.location
  /* istanbul ignore next */
  private getLocationHref(): string {
    return window.location.href;
  }
}
