// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .dx-popup.pp-default-popup .dx-popup-title.dx-toolbar .dx-toolbar-label {
  text-transform: none;
}
::ng-deep .pp-open-close-popup .dx-popup-content {
  padding-right: 0;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
}
::ng-deep .pp-open-close-popup .pp-main-popup-content-open-close-content {
  padding: 40px 26px !important;
}
::ng-deep .pp-open-close-popup .pp-bottom-popup-buttons {
  height: 50px;
  box-sizing: border-box;
  border-top: 1px solid rgb(210, 212, 212);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 24px;
}
::ng-deep .pp-open-close-popup .pp-bottom-popup-buttons {
  margin-top: auto;
}
::ng-deep .pp-open-close-popup .pp-bottom-popup-buttons dx-button {
  margin-left: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/Modals/open-close-location-popup/open-close-location-popup.component.scss"],"names":[],"mappings":"AAEI;EACE,oBAAA;AADN;AAWI;EACE,gBAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;AATN;AAWI;EACE,6BAAA;AATN;AAWI;EACE,YAAA;EACA,sBAAA;EACA,wCAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;AATN;AAYI;EACE,gBAAA;AAVN;AAaI;EACE,gBAAA;AAXN","sourcesContent":[":host {\r\n  ::ng-deep {\r\n    .dx-popup.pp-default-popup .dx-popup-title.dx-toolbar .dx-toolbar-label {\r\n      text-transform: none;\r\n    }\r\n\r\n    .message {}\r\n\r\n  }\r\n}\r\n\r\n::ng-deep {\r\n  .pp-open-close-popup {\r\n    .dx-popup-content {\r\n      padding-right: 0;\r\n      padding-left: 0;\r\n      padding-top: 0;\r\n      padding-bottom: 0;\r\n    }\r\n    .pp-main-popup-content-open-close-content {\r\n      padding: 40px 26px !important;\r\n    }\r\n    .pp-bottom-popup-buttons {\r\n      height: 50px;\r\n      box-sizing: border-box;\r\n      border-top: 1px solid rgba(210, 212, 212, 1);\r\n      display: flex;\r\n      align-items: center;\r\n      justify-content: flex-end;\r\n      padding-right: 24px;\r\n    }\r\n\r\n    .pp-bottom-popup-buttons {\r\n      margin-top: auto;\r\n    }\r\n\r\n    .pp-bottom-popup-buttons dx-button {\r\n      margin-left: 8px;\r\n    }\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
