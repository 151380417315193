import { Directive } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[decimalNumberFormatValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: DecimalNumberFormatValidatorDirective,
      multi: true,
    },
  ],
})
export class DecimalNumberFormatValidatorDirective implements Validator {

  validate(formControl: UntypedFormControl) {
    const regex = new RegExp(/^(?!.*,\d{3})(?:\d+|\d{1,3}(?:\d{3})*)(?:\.\d+)?$/);

    if (formControl.value === null || formControl.value === "" || formControl.value == undefined) {
      return null;
    }

    if (!regex.test(formControl.value)) {
      return { badFormat: true };
    }

    return null;
  }
}
