import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import config from "devextreme/core/config";
import { catchError, Observable, of } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class DevextremeLicenseService {
  constructor(private _httpClient:HttpClient){

  }
  /**
   * Loads the DevExtreme license key.
   * @returns An Observable that resolves when the license key is loaded.
   */
  loadLicenseKey(): Observable<{ licenseKey: string }> {
    return this._httpClient
      .get<{ licenseKey: string }>(`${environment.backendControllerUrl}/DevextremeLicense/GetDevextremeLicenseKey`)
      .pipe(
        catchError((error) => {
          console.error('Error fetching license key', error);
          return of({ licenseKey: '' });
        })
      );
  }

  /**
   * Apply the DevExtreme license key to the config.
   */
  applyLicense(licenseKey: string): void {
    if (licenseKey) {
      config({ licenseKey });
    }
  }
}
