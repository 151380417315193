import { AuthenticationService, AuthLibraryModule, SessionModalService } from '@peoplepower/auth-library';
import { SharedModule } from "./shared.module";
import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";
import { ForbiddenComponent } from "./ForbiddenComponent/forbidden.component";
import { PopupInfoComponent } from "./Modals/popup-info/popup-info.component";
import { ConfirmDeletePopupComponent } from "./Modals/confirm-delete-popup/confirm-delete-popup.component";
import { ClientComponent } from "./client/client.component";
import { InfoDeleteEntityTypePopupComponent } from "./Modals/info-delete-entity-type-popup/info-delete-entity-type-popup.component";
import { OpenCloseLocationPopupComponent } from "./Modals/open-close-location-popup/open-close-location-popup.component";

import { HideDropdownDirective } from "./Directives/hide-dropdown.directive";
import { HttpErrorInterceptorService } from "./Services/exceptions/http-error-interceptor.service";
import { UiExceptionHandlerService } from "./Services/exceptions/ui-exception-handler.service";
import { ReportSignalRService } from "./Services/signalR/report-signal-r.service";
import { EntityPopupInfoComponent } from './Modals/entity-popup-info/entity-popup-info.component';
import { ReportPopupInfoComponent } from './Modals/report-popup-info/report-popup-info.component';
import { UnlockConfirmPopupInfoComponent } from './Modals/unlock-confirm-popup-info/unlock-confirm-popup-info.component';
import { ConfigurationService } from "./Services/configuration.service";
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';

export function initializeApp(_reportSignalRService: ReportSignalRService) {
  return () => _reportSignalRService.startConnection().toPromise();
}

@NgModule({
  declarations: [
    AppComponent,
    ForbiddenComponent,
    PopupInfoComponent,
    ConfirmDeletePopupComponent,
    ClientComponent,
    InfoDeleteEntityTypePopupComponent,
    OpenCloseLocationPopupComponent,
    HideDropdownDirective,
    EntityPopupInfoComponent,
    ReportPopupInfoComponent,
    UnlockConfirmPopupInfoComponent,
  ],
  imports: [
    NgIdleKeepaliveModule.forRoot(),
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    AuthLibraryModule
  ],
  providers: [
    AuthenticationService,
    ConfigurationService,
    SessionModalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorService,
      multi: true
    },
     {
       provide: ErrorHandler,
       useClass: UiExceptionHandlerService
     },
    ReportSignalRService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],

})
export class AppModule {
  constructor() {
  }
}
