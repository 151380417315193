import { Directive } from "@angular/core";
import { UntypedFormControl, NG_VALIDATORS, Validator } from "@angular/forms";

@Directive({
  selector: "[specialChars]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: SpecialCharactersValidatorDirective,
      multi: true,
    },
  ],
})
export class SpecialCharactersValidatorDirective implements Validator {

  validate(formControl: UntypedFormControl) {
    const regex = new RegExp(/[|<>]+/);
    if (regex.test(formControl.value)) {
      return { symbols: true };
    }
  }
}
