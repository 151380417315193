import { CustomerRole } from "./../types/customerRole";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Store } from "../DATA/store";
@Injectable({
  providedIn: "root",
})
export class PermissionGuard  {
  constructor(private _store: Store, private _router: Router) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const isRoutePermitted = (
      route.data.permissions as CustomerRole[]
    ).includes(this._store.role);
    if (!isRoutePermitted) this._router.navigate(['error']);
    return isRoutePermitted;
  }
}
