import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable, catchError, map, of } from 'rxjs';
import { DevextremeLicenseService } from './devextreme-license.sevice';


@Injectable({
  providedIn: 'root',
})
export class LicenseResolver implements Resolve<void> {
  constructor(private licenseService: DevextremeLicenseService) {}

  /**
   * Ensures the DevExtreme license key is loaded before route activation.
   * @returns An Observable that resolves when the license key is applied.
   */
  resolve(): Observable<void> {
    return this.licenseService.loadLicenseKey().pipe(
      map((response) => {
        if (response?.licenseKey) {
          this.licenseService.applyLicense(response.licenseKey);
        }
      }),
      catchError((error) => {
        console.error('Error loading license:', error);
        return of(void 0);
      })
    );
  }
}