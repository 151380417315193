import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { Report } from "../Models/createdReport";
import {Entity } from "../Models/entity";
import { ReportInfo } from "../Models/reportInfo";


@Injectable({
  providedIn: "root",
})
export class ReportService {
  constructor(private _http: HttpClient) { }

  // Report
  public getReports(): Observable<Report[]> {
    return this._http.get<Report[]>(
      environment.backendControllerUrl + "/Report/GetReports"
    );
  }

  public getReportById(id: number): Observable<Report> {
    return this._http.get<Report>(
      environment.backendControllerUrl + `/Report/GetReports?id=${id}`
    );
  }

  public createReport(report: Report): Observable<Report> {
    return this._http.post<Report>(
      environment.backendControllerUrl + "/Report/CreateReport",
      report
    );
  }

  public updateReport(report: Report): Observable<Report> {
    return this._http.post<Report>(
      environment.backendControllerUrl + "/Report/UpdateReport",
      report
    );
  }

  public deleteReport(reportIds: number[]): any {
    return this._http.post(
      environment.backendControllerUrl + `/Report/DeleteReport`,
      {
        ids: reportIds,
      }
    );
  }

  public logViewReport(report: Report, reportTypeName: string): any {
    return this._http.post(
      environment.backendControllerUrl + `/Report/LogViewReport`,
      this.constructReportInfo(report, reportTypeName)
    );
  }

  public logExportReport(report: Report, reportTypeName: string): any {
    return this._http.post(
      environment.backendControllerUrl + `/Report/LogExportReport`,
      this.constructReportInfo(report, reportTypeName)
    );
  }

  public getLockedElements(customer: string): Observable<string[]> {
    return this._http.get<string[]>(
      environment.backendControllerUrl + `/Report/GetLockedElements?customer=${customer}`
    );
  }

  public lockElement(id: string): any {
    return this._http.post(
      environment.backendControllerUrl + `/Report/LockElement`, {
      Id: id,
    }
    );
  }

  public unlockElement(id: string): any {
    return this._http.post(
      environment.backendControllerUrl + `/Report/UnlockElement`, {
      Id: id,
    }
    );
  }

  public reportCreationEditOngoing(customer: string): Observable<boolean> {
    return this._http.get<boolean>(
      environment.backendControllerUrl + `/Report/ReportCreationEditOngoing?customer=${customer}`
    );
  }

  public reportCreationOngoing(customer: string): Observable<boolean> {
    return this._http.get<boolean>(
      environment.backendControllerUrl + `/Report/ReportCreationOnGoing?customer=${customer}`
    );
  }

  public reportCreationOrEditOngoing(customer: string): Observable<boolean> {
    return this._http.get<boolean>(
      environment.backendControllerUrl + `/Report/ReportCreationOrEditOngoing?customer=${customer}`
    );
  }

  public unlockAllReports(customer: string): Observable<boolean> {
    return this._http.get<boolean>(
      environment.backendControllerUrl + `/Report/UnlockAllElements?customer=${customer}`
    );
  }

  public startCreateReport(
  reportGUID: string,
  reportName: string,
  date: string,
  compareDate: string | null,
  entities: Entity[],
  compareEntities: Entity[]
): void {
    console.log("Start create report");
    let url = `${environment.backendControllerUrl}/CustomReport/StartCreateReport?reportId=${reportGUID}&reportName=${reportName}&referenceDate=${date}`;
    if (compareDate !== null) {
      url += `&compareDate=${compareDate}`;
    }
    this._http.post(
    url,
    {
      entities: entities,
      compareEntities: compareEntities
    }
  ).subscribe(
    response => console.log('Report started successfully', response),
    error => console.error('Error starting report', error)
  );
}

  public getReportEntities(
  reportName: string,
  date: string,
  scenarioId: number | undefined,
  entityIds: number[]
): Observable<Entity[]> {
  const parameter = scenarioId ? `scenarioId=${scenarioId}` : `date=${date}`;
  return this._http.post<Entity[]>(
    `${environment.backendControllerUrl}/CustomReport/GetReportEntities?reportName=${reportName}&${parameter}`,
    entityIds
  );
}

  private constructReportInfo(report: Report, reportTypeName: string): ReportInfo {
    const reportInfo: ReportInfo = {
      Id: report.Id,
      Name: report.Name,
      ReportTypeName: reportTypeName
    };

    return reportInfo;
  }
}
