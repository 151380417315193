import { Injectable } from '@angular/core';
import { NotifyRefreshReportsService } from '../utility/notify-refresh-reports.service';
import { ShareService } from '../share.service';
import { TranslateService } from '@ngx-translate/core';
import { LockingElementService } from '../locking-element.service';

@Injectable({
  providedIn: 'root'
})
export class SignalrActionsService {
  ///IMPORTANT: Private var to store the status change, used to avoid validations not updating in time.
  private reportStatusChanged: boolean = true;

  constructor() { }

  /// Method used to do the required action receiving a signalR creation event.
  public reportCreated(notifyRefreshReportService: NotifyRefreshReportsService,
    shareService: ShareService, translate: TranslateService): void {
    notifyRefreshReportService.displayRefreshingMessage(translate.instant("ReportCreated"));
    shareService.emitReportDataUpdate();
    shareService.emitReportCreationOrEditFinished();
    shareService.emitReportCreationStatusChange(!this.reportStatusChanged);
  }

  /* Method used to emit the cancelation status for report creation to components.
  Also displays a notify message. */
  public reportCreateCanceled(notifyRefreshReportService: NotifyRefreshReportsService,
    shareService: ShareService, translate: TranslateService): void {
    notifyRefreshReportService.displayRefreshingMessage(translate.instant("ReportCreatedCanceled"));
    shareService.emitReportCreationStatusChange(!this.reportStatusChanged);
    shareService.emitReportCreationOrEditFinished();
  }

  /// Emit the popup opened message to other components.
  public reportPopupOpen(shareService: ShareService): void {
    shareService.emitReportCreationOrEdit();
  }

  /* Locks the report for the other users.
  Displays a relevant message for the other users.
  Emits the message to the other components. */
  public reportEditStarted(notifyRefreshReportService: NotifyRefreshReportsService,
    shareService: ShareService, translate: TranslateService, elementLockingService: LockingElementService, id: string): void {
    notifyRefreshReportService.displayRefreshingMessage(translate.instant("ReportBeingEdited"));
    elementLockingService.lockElement(id);
    shareService.emitReportCreationOrEdit();
  }

  /* Blocks the new button for the other users.
  Displays a relevant message for the other users.
  Emits the message of creation to the other components. */
  public reportCreateStarted(notifyRefreshReportService: NotifyRefreshReportsService,
    shareService: ShareService, translate: TranslateService): void {
    notifyRefreshReportService.displayRefreshingMessage(translate.instant("ReportBeingCreated"));
    shareService.emitReportCreationStatusChange(this.reportStatusChanged);
  }

  /* Unlocks the element for the other users.
  Displays a relevant message for the other users.
  Sends the relevant events to the other users.*/
  public reportEditDone(notifyRefreshReportService: NotifyRefreshReportsService,
    shareService: ShareService, translate: TranslateService, elementLockingService: LockingElementService, id: string): void {
    elementLockingService.unlockElement(id);
    notifyRefreshReportService.displayRefreshingMessage(translate.instant("ReportEdited"));
    shareService.emitReportDataUpdate();
    shareService.emitReportCreationOrEditFinished();
  }

  /* Unlocks the element for the other users.
  Displays a relevant message for the other users.
  Sends the relevant events to the other users.*/
  public reportEditAbandon(notifyRefreshReportService: NotifyRefreshReportsService,
    shareService: ShareService, translate: TranslateService, elementLockingService: LockingElementService, id: string): void {
    notifyRefreshReportService.displayRefreshingMessage(translate.instant("ReportCanceledEdited"));
    elementLockingService.unlockElement(id);
    shareService.emitReportCreationOrEditFinished();
  }

  /* Special case where the client forcefully stopped editing (no internet connection tab close etc).
  Sends the required events to the other users.
  Back-end handles gracefully disconnection and unlocking.*/
  public forcefullEditCancel(notifyRefreshReportService: NotifyRefreshReportsService,
    shareService: ShareService, translate: TranslateService): void {
    notifyRefreshReportService.displayRefreshingMessage(translate.instant("ReportEdited"));
    shareService.emitClientForcefullyDisconnected();
    shareService.emitReportCreationOrEditFinished();
  }

  /* Special case where the client forcefully stopped creation process (no internet connection tab close etc).
  Sends the required events to the other users.
  Back-end handles gracefully disconnection.*/
  public forcefullCreationCancel(notifyRefreshReportService: NotifyRefreshReportsService,
    shareService: ShareService, translate: TranslateService): void {
    notifyRefreshReportService.displayRefreshingMessage(translate.instant("ReportCreatedCanceled"));
    shareService.emitReportCreationStatusChange(!this.reportStatusChanged);
    shareService.emitReportCreationOrEditFinished();
  }

  /* Displays a relevant message to the other users.
  Notifies the other users to refresh the data. */
  public reportDeleted(notifyRefreshReportService: NotifyRefreshReportsService,
    shareService: ShareService, translate: TranslateService, deletedIds: number[]): void {
    notifyRefreshReportService.displayRefreshingMessage(translate.instant("ReportDeleted"));
    shareService.emitReportsDeletedFinished(deletedIds);
    shareService.emitReportDataUpdate();
  }

  /* Unlocks all the reports, unblocks the new button.
  Unblocks the buttons for the entity type and type properties window.
  Displays a relevant message to the users.
  Handles disconnection on the server gracefully.*/
  public unlockEverything(notifyRefreshReportService: NotifyRefreshReportsService,
    shareService: ShareService, translate: TranslateService): void {
    notifyRefreshReportService.displayRefreshingMessage(translate.instant("ReportsUnlockedByAdmin"));
    shareService.emitNewEnabledFromAdministrator();
    shareService.emitReportCreationOrEditFinished();
    shareService.emitReportUnlockFromAdmin();
  }
}
