import { CustomerRole } from "./../types/customerRole";

import { take } from "rxjs/operators";
import { CustomConfig } from "./../Models/customConfig";

import { environment } from "./../../environments/environment";
import { Quadrant } from "./../Models/quadrant";
import { ReportTypesService } from "./../Services/report-types.service";
import { ReportType } from "./../Models/reportType";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TypeDefinition } from "../Models/typeDefinition";
import { User } from "@peoplepower/auth-library";
import { EntityProperty } from "../Models/entityProperty";
import { EntityTypeProperty } from "../Models/entityTypeProps";
import { EntityType } from "../Models/entityType";
import { UserPermission } from "../types/userPermission";

@Injectable({
  providedIn: "root",
})
export class Store {
  entityTypes: EntityType[];
  entityTypeProperties: EntityTypeProperty[];
  entityProperties: EntityProperty[];
  quadrants: Quadrant[];
  customConfigs: CustomConfig[];
  reportTypes: ReportType[];
  typeDefinitions: TypeDefinition[];

  reportTypeNameByReportTypeId: Map<number, string> = new Map();
  defaultPropertyByTypeName: Map<string, string> = new Map();
  typeDefintionsMap: Map<number, string> = new Map();
  role: CustomerRole;
  permissions: UserPermission[];

  toIndex: number;
  fromIndex: number;

  linkPropertiesGridsDataSource: any[];
  user: User;
  asBuiltUrl: string;
  selectedChips: any[];

  constructor(
    private http: HttpClient,
    private reportTypeService: ReportTypesService
  ) { }

  updateEntityTypes() {
    this.getTypes()
      .pipe(take(1))
      .subscribe((entityTypes) => {
        this.entityTypes = entityTypes;
        entityTypes.forEach((entityType) => {
          this.defaultPropertyByTypeName.set(
            entityType.Name,
            entityType.DefaultProperty.Name
          );
        });
      });
  }

  updateEntityTypeProperties() {
    this.getEntityTypeProperties()
      .pipe(take(1))
      .subscribe((entityTypeProperties: EntityTypeProperty[]) => {
        this.entityTypeProperties = entityTypeProperties;
      });
  }

  updateEntityProperties() {
    this.getEntityProperties()
      .pipe(take(1))
      .subscribe((entityProperties: EntityProperty[]) => {
        this.entityProperties = entityProperties;
      });
  }
  getTypes(): Observable<EntityType[]> {
    return this.http
      .get<EntityType[]>(environment.backendControllerUrl + "/EntityType/GetTypes");
  }

  getEntityTypeProperties(): Observable<EntityTypeProperty[]> {
    return this.http.get<EntityTypeProperty[]>(
      environment.backendControllerUrl + "/EntityTypeProperty/GetEntityTypeProperties");
  }
  getReportTypes(): Observable<ReportType[]> {
    return this.reportTypeService.getReportTypes();
  }
  getEntityProperties(): Observable<EntityProperty[]> {

    return this.http.get<EntityProperty[]>(environment.backendControllerUrl + "/Property/GetProperties");
  }

  getPropertiesByType(typeName: string): EntityProperty[] {
    const entityTypeId = this.entityTypes.find((t) => t.Name == typeName).Id;
    const entityTypeProperties = this.entityTypeProperties.filter(
      (etp) => etp.EntityTypeId == entityTypeId
    );
    const properties: EntityProperty[] = [];
    this.entityProperties.forEach((property) => {
      if (entityTypeProperties.some((etp) => etp.PropertyId == property.Id))
        properties.push(property);
    });
    return properties;
  }

  public getUserRole(): string {
    return this.role;
  }
  public isWindowInIframe(): boolean {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }


  /**
   * Checks if the user has a specific right.
   * 
   * @param rightName The name of the right to check.
   * @returns True if the user has the right, false otherwise.
   */
  public hasRight(rightName: string): boolean {
    return this.role == rightName;
  }
}
