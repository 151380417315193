import { SharedModule } from "./shared.module";
import { AppRoutingModule } from "./app-routing.module";
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import {
  faBuilding as farBuilding,
  faSquare as farSquare,
} from "@fortawesome/free-regular-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import {
  faGripLines,
  fas,
  faSchool,
  faUniversity,
  faArchway,
  faGraduationCap,
  faGripVertical,
  faCheck,
  faIndent,
  faTimes,
  faChartBar,
  faTh,
  faEdit,
  faLanguage,
  faGlobe,
  faBars,
  faVectorSquare,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { FaIconLibrary } from "@fortawesome/angular-fontawesome";
import { AppComponent } from "./app.component";
import { ForbiddenComponent } from "./ForbiddenComponent/forbidden.component";
import { PopupInfoComponent } from "./Modals/popup-info/popup-info.component";
import { ConfirmDeletePopupComponent } from "./Modals/confirm-delete-popup/confirm-delete-popup.component";
import { ClientComponent } from "./client/client.component";
import { InfoDeleteEntityTypePopupComponent } from "./Modals/info-delete-entity-type-popup/info-delete-entity-type-popup.component";
import { OpenCloseLocationPopupComponent } from "./Modals/open-close-location-popup/open-close-location-popup.component";
import {
  AuthenticationService,
  AuthLibraryModule,
  SessionModalService,
} from "@peoplepower/auth-library";
import { HideDropdownDirective } from "./Directives/hide-dropdown.directive";
import { HttpErrorInterceptorService } from "./Services/exceptions/http-error-interceptor.service";
import { UiExceptionHandlerService } from "./Services/exceptions/ui-exception-handler.service";
import { ReportSignalRService } from "./Services/signalR/report-signal-r.service";
import { EntityPopupInfoComponent } from './Modals/entity-popup-info/entity-popup-info.component';
import { ReportPopupInfoComponent } from './Modals/report-popup-info/report-popup-info.component';
import { UnlockConfirmPopupInfoComponent } from './Modals/unlock-confirm-popup-info/unlock-confirm-popup-info.component';
import { ConfigurationService } from "./Services/configuration.service";

export function initializeApp(_reportSignalRService: ReportSignalRService) {
  return () => _reportSignalRService.startConnection().toPromise();
}

@NgModule({
  declarations: [
    AppComponent,
    ForbiddenComponent,
    PopupInfoComponent,
    ConfirmDeletePopupComponent,
    ClientComponent,
    InfoDeleteEntityTypePopupComponent,
    OpenCloseLocationPopupComponent,
    HideDropdownDirective,
    EntityPopupInfoComponent,
    ReportPopupInfoComponent,
    UnlockConfirmPopupInfoComponent,
  ],
  imports: [
    NgIdleKeepaliveModule.forRoot(),
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    AuthLibraryModule,
  ],
  providers: [
    AuthenticationService,
    ConfigurationService,
    SessionModalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorService,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: UiExceptionHandlerService
    },
    ReportSignalRService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],

})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas, far);
    library.addIcons(
      farBuilding,
      farSquare,
      faUniversity,
      faGripLines,
      faSchool,
      faArchway,
      faGraduationCap,
      faGripVertical,
      faCheck,
      faIndent,
      faTimes,
      faChartBar,
      faTh,
      faEdit,
      faLanguage,
      faGlobe,
      faBars,
      faVectorSquare,
      faQuestion
    );
  }
}
