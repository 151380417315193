import { ShareService } from "src/app/Services/share.service";
import { NavigationStart, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { locale, loadMessages } from "devextreme/localization";
import nlMessages from "devextreme/localization/messages/nl.json";
import { Store } from "./DATA/store";
import { ReportSignalRService } from "./Services/signalR/report-signal-r.service";
import { IframeService } from "./Services/iframe.service";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  locale: string;

  constructor(
    private router: Router,
    private _share: ShareService,
    private _store: Store,
    private _reportSignalRService: ReportSignalRService,
    private _iframeService: IframeService
  ) {
    this.addReferrerToSessionStorage();
    this.validateEvent();
    this.locale = this.getLocale();
    locale(this.locale);
    loadMessages(nlMessages);
    this.redirection();
  }
  ngOnInit(): void {
    this._reportSignalRService.startConnection().toPromise().then(() => {
      this._reportSignalRService.subscribeToListeners();
    });
  }

  private redirection(): void {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart && event.url.includes("reportId")) {
        //we store the ID when an link is pressed from an external link, helps when the user is not logged in and for redirection to the report template
        localStorage.setItem("reportToOpen", event.url.split("reportId=")[1].toString());
        this.redirectToReportId(event);
      }
    });
  }

  redirectToReportId(event: NavigationStart) {
    const reportId: number = +event.url.split("reportId=")[1];
    const client: string = event.url.split("/")[1];
    this.router.navigate([`/${client}/reports`]).then(() => {
      this._share.reportIdFromUrl = reportId;
    });
  }

  /**
   * Type: Function
   * Functionality : This function helps navigation to report tab if an ID is present in url
   * */
  redirectToReportFromExternalLink(clientUrl: string, reportId: string): void {

    this.router.navigate([`/${clientUrl}/reports`]).then(() => {
      this._share.reportIdFromUrl = + reportId;
      localStorage.removeItem("reportToOpen");
    });
  }

  validateEvent(): void {
    window.addEventListener("message", (event) => {
      try{
        if (this._iframeService.checkHostName(event.origin)) {
          const information = JSON.parse(event.data);
          const language: string = information.language.toLowerCase();
          const localeLanguage: string = localStorage.getItem("locale");
          if (information.asBuiltUrl) {
            this._store.asBuiltUrl = information.asBuiltUrl;
          }

          if (!language.includes(localeLanguage)) {
            localStorage.setItem("locale", language.substr(0, 2));
            document.location.reload();
          }
        }
      } catch(e){
        return;
      }
    });
  }

  communicationRejected() {
    this.router.navigate(([`error`]))
  }

  getLocale() {

    const locale = localStorage.getItem("locale");

    //locale can be null or emptyString
    if (locale != null && locale != "")
      return locale;

    //this would be defualt language if locale is not initialized
    localStorage.setItem("locale", "nl");
    return "nl";
  }

  /// Method used to add the AsBuilt hostname to the session storage of the browser.
  /// If there is already a value in the session storage for hostname the method doesn't do anything.
  private addReferrerToSessionStorage(): void {
    if (window.sessionStorage && window.sessionStorage.hostname) {
      return;
    }
    if (!document.referrer || document.referrer.length == 0) {
      return;
    }
    let hostName = new URL(document.referrer).hostname;
    if (this._iframeService.PEOPLE_POWER_SUBDOMAIN_PREFIXES.some((subdomain) => hostName.startsWith(subdomain))) {
      window.sessionStorage.hostname = hostName;
    }
  }

}
