import { PermissionGuard } from "./Guards/permission.guard";
import { ClientComponent } from "./client/client.component";
import {
  RouterModule,
} from "@angular/router";

import { ForbiddenComponent } from "./ForbiddenComponent/forbidden.component";

import { NgModule } from "@angular/core";
import { AuthGuard } from "./Guards/auth.guard";
import { LicenseResolver } from "./Services/license-resolver.service";


@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot([
      { path: "error", component: ForbiddenComponent },
      {
        path: ":client",
        component: ClientComponent,
        canActivate: [AuthGuard],
        resolve:{license:LicenseResolver},
        children: [
          {
            path: "",
            redirectTo: 'space-management',
            pathMatch: "full",
          },
          // For edit and admin roles
          {
            path: "space-management",
            loadChildren: () =>
              import("./SpaceManagement/space-management.module").then(
                (m) => m.SpaceManagementModule
              ),
            canActivate: [PermissionGuard],
            data: { permissions: ["view", "edit", "manage", "admin"] },
          },
          // For admin role
          {
            path: "editor",
            loadChildren: () =>
              import("./Editor/editor.module").then((m) => m.EditorModule),
            canActivate: [PermissionGuard],
            data: { permissions: ["admin"] },
          },
          // For edit and admin roles
          {
            path: "scenarios",
            loadChildren: () =>
              import("./Scenarios/scenarios.module").then((m) => m.ScenariosModule),
            canActivate: [PermissionGuard],
            data: { permissions: ["manage", "admin"] },
          },
          // For edit and admin roles
          {
            path: "reports",
            loadChildren: () =>
              import("./Reports/reports.module").then((m) => m.ReportsModule),
            canActivate: [PermissionGuard],
            data: {
              permissions: ["view", "edit", "manage", "admin"]
            },
          },
          { path: "**", component: ForbiddenComponent },
        ],
      },
      { path: "**", redirectTo: "" }], {
      scrollPositionRestoration: "enabled",
      paramsInheritanceStrategy: "always"
    }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule { }