import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ConfigurationService {

  constructor(private http: HttpClient) { }

  public isApplicationAllowedOutsideWebviewer(): Observable<boolean> {
    return this.http.get<boolean>(window.location.origin + '/api/Config/IsApplicationAllowedOutsideWebviewer')    
  }

  public shouldOpenCloudViewerSelf(): Observable<boolean> {
    return this.http.get<boolean>(window.location.origin + '/api/Config/OpenCloudViewerSelf')
  }

}