// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .client-container {
  width: 100%;
  height: 100vh;
  display: flex;
}
:host .main-content {
  width: 100%;
  min-width: calc(100% - 240px);
  height: 100vh;
}
:host ::ng-deep .dx-popup-wrapper > .dx-overlay-content {
  border: none;
}
:host ::ng-deep .dx-dropdowneditor.dx-state-hover:not(.dx-custom-button-hovered) .dx-dropdowneditor-icon,
:host ::ng-deep .dx-dropdowneditor.dx-state-active .dx-dropdowneditor-icon {
  background-color: transparent;
}
:host ::ng-deep .dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon,
:host ::ng-deep .dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon {
  background-color: transparent;
}
:host ::ng-deep .dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon .dx-datebox-calendar.pp-date-box .dx-dropdowneditor-icon::before,
:host ::ng-deep .dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon .dx-datebox-calendar.pp-date-box .dx-dropdowneditor-icon::before {
  color: var(--DarkBlue);
}

::ng-deep .pp-select-box .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-list-item-selected,
::ng-deep .pp-select-box .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-state-active,
::ng-deep .pp-select-box .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item.dx-list-item-selected.dx-state-hover:not(.dx-state-focused) {
  text-shadow: none;
}`, "",{"version":3,"sources":["webpack://./src/app/client/client.component.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,aAAA;EACA,aAAA;AAAJ;AAGE;EACE,WAAA;EACA,6BAAA;EACA,aAAA;AADJ;AAIE;EACE,YAAA;AAFJ;AAMI;;EAGE,6BAAA;AALN;AAOI;;EAEE,6BAAA;AALN;AAMM;;EACE,sBAAA;AAHR;;AASE;;;EASA,iBAAA;AAZF","sourcesContent":[":host {\r\n  .client-container {\r\n    width: 100%;\r\n    height: 100vh;\r\n    display: flex;\r\n  }\r\n\r\n  .main-content {\r\n    width: 100%;\r\n    min-width: calc(100% - 240px);\r\n    height: 100vh;\r\n  }\r\n\r\n  ::ng-deep .dx-popup-wrapper > .dx-overlay-content {\r\n    border: none;\r\n  }\r\n\r\n  ::ng-deep {\r\n    .dx-dropdowneditor.dx-state-hover:not(.dx-custom-button-hovered)\r\n      .dx-dropdowneditor-icon,\r\n    .dx-dropdowneditor.dx-state-active .dx-dropdowneditor-icon {\r\n      background-color: transparent;\r\n    }\r\n    .dx-dropdowneditor.dx-dropdowneditor-active .dx-dropdowneditor-icon,\r\n    .dx-dropdowneditor-button.dx-state-active .dx-dropdowneditor-icon {\r\n      background-color: transparent;\r\n      .dx-datebox-calendar.pp-date-box .dx-dropdowneditor-icon::before {\r\n        color: var(--DarkBlue);\r\n      }\r\n    }\r\n  }\r\n}\r\n::ng-deep{\r\n  .pp-select-box\r\n  .dx-list:not(.dx-list-select-decorator-enabled)\r\n  .dx-list-item.dx-list-item-selected,\r\n.pp-select-box\r\n  .dx-list:not(.dx-list-select-decorator-enabled)\r\n  .dx-list-item.dx-state-active,\r\n.pp-select-box\r\n  .dx-list:not(.dx-list-select-decorator-enabled)\r\n  .dx-list-item.dx-list-item-selected.dx-state-hover:not(.dx-state-focused) {\r\n  text-shadow: none;\r\n}}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
