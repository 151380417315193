import { EntityProperty } from "./../Models/entityProperty";
import { EntityTypeProperty } from "src/app/Models/entityTypeProps";
import { Entity } from "src/app/Models/entity";
import { SMTreeViewComponent } from "./../SpaceManagement/SM-treeView/SM-treeView.component";
import { Observable } from "rxjs";
import { ShareService } from "src/app/Services/share.service";
import { RGTreeViewComponent } from "./../ReportGenerator/RG-treeView/RG-treeView.component";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { SMDataGridComponent } from "../SpaceManagement/SM-dataGrid/SM-dataGrid.component";
import { RGDataGridComponent } from "../ReportGenerator/RG-dataGrid/RG-dataGrid.component";
import { RGPivotGridComponent } from "../ReportGenerator/RG-pivotGrid/RG-pivotGrid.component";
import { EditPropertyComponent } from "../Editor/edit-property/edit-property.component";
import { SMDataGridEditComponent } from "../SpaceManagement/sm-data-grid-edit/sm-data-grid-edit.component";
import { shareReplay, take } from "rxjs/operators";
import { EntityType } from "../Models/entityType";
import { EntitiesFilter } from "../Models/entitiesFilter";
import { environment } from "../../environments/environment";
import { EntityTypesToPost } from "../Models/entityTypesToPost";
import { TypeDefinition } from "../Models/typeDefinition";
import { EntityTypePropertiesDictionaryWrapper } from "../Models/entity-type-properties-dictionary-wrapper";
import { EntityInfo } from "../Models/entityInfo";
import { ChangeLocation } from "../Models/changeLocation";

@Injectable({
  providedIn: "root",
})
export class DataHandlerService {
  static GridComponent: SMDataGridComponent;
  static SMTreeViewComponent: SMTreeViewComponent;
  static GridComponentPivot: RGDataGridComponent;
  static PivotComponent: RGPivotGridComponent;
  static TreeViewPivot: RGTreeViewComponent;
  static PropertyComponent: EditPropertyComponent;
  static EditEntityComponent: SMDataGridEditComponent;

  tokenObj: any;
  token = localStorage.getItem("token");
  method: any;
  selectedDate: string = null;
  headers = new HttpHeaders().set("Content-Type", "application/json");
  scenarioId: number;


  constructor(
    public http: HttpClient,
    private share: ShareService,
  ) {
    let date = new Date();
    this.selectedDate = date.toISOString().slice(0, 10);
    this.initializeSubscriptions();
  }

  private initializeSubscriptions(): void {
    this.share.recievedDateEmitter.subscribe((date) => {
      this.selectedDate = date;
    });
    this.share.onScenarioChanged.subscribe((scenarioId: number) => {
      this.scenarioId = scenarioId;
    });
  }

  /***
   * API that retrieves entities contained in the custom report tree
   * @param filters EntitiesFilter, filters to be specified regarding tree nodes
   * */
  public getAllEntitiesForCustomReportTreeview(filters: EntitiesFilter): Observable<Entity[]> {
    filters.scenarioId = this.scenarioId;
    return this.http.post<Entity[]>(
      environment.backendControllerUrl +
      "/Entity/GetAllEntitiesForCustomReportTreeview",
      filters
    );
  }
  /***
   * API that retrieves an entity based on the ID provided
   * @param entityId Entity ID, used to get an entity of a specific id
   * */
  public getEntityById(entityId: number): Observable<Entity> {
    const body = {
      entityId: entityId,
      addHasChildren: true,
      toDate:this.selectedDate
    }
    return this.http.post<Entity>(
      environment.backendControllerUrl +
      "/Entity/GetEntityById",
      body
    );
  }

  /***
  * API that retrieves an entity based on the ID provided, used just to check HasChildren parameter
   @param entityId Entity ID, used to get an entity of a specific id
  * */
  public isEntityWithChildren(entityId: number): Promise<Entity[]> {
    const body = {
      entityId: entityId,
      addHasChildren: true
    }
    return this.http.post<Entity[]>(
      environment.backendControllerUrl +
      "/Entity/IsEntityWithChildren",
      body
    ).toPromise();
  }


  /**
   * API that return all Properties
   * */
  public returnProperties(): Observable<EntityProperty[]> {
    return this.http.get<EntityProperty[]>(environment.backendControllerUrl + "/Property/GetProperties", {});
  }

  /**
   * API that reutrn all Entity Types
   * */
  public getTypes(): Observable<EntityType[]> {
    return this.http
      .get<EntityType[]>(environment.backendControllerUrl + "/EntityType/GetTypes")
      .pipe(shareReplay());
  }

  /**
   * API that return childs of an entity when we know the id of the parent entity
   * @param id
   * @returns array of child entities
   * */
  public getEntitiesByParentId(id: number): Observable<Entity[]> {
    const body = {
      parentiD: id
    }
    return this.http.post<Entity[]>(environment.backendControllerUrl + "/Entity/GetEntityByParentId", body);
  }

  /**
   * API that return child of an entity when we know the id of the parent entity, can also retrieve entities that are closed
   * @param id number,   of the parent entity
   * @param returnClosedEntitites boolean, true for returning closed entities, false otherwise
   * */
  public async getEntitiesAsync(id: number, returnClosedEntitites: boolean): Promise<Entity[]> {
    const givenId = id == null ? -1 : id;
    const body = {
      parentId: givenId,
      addHasChildren: true,
      toDate: this.selectedDate,
      returnClosed: returnClosedEntitites
    }
    return await this.http.post<Entity[]>(environment.backendControllerUrl + "/Entity/GetEntitiesWithChildrens", body).toPromise();
  }

  /**
   * API that return children of an entity when we know the id of the parent entity
   * @param id number, id of the parent entity
   * @param fromType string, sets the level from where
   * */
  public async getEntitiyChildrenAsync(id: number,fromType:string): Promise<Entity[]> {
    const givenId = id == null ? -1 : id;
    const body = {
      parentId: givenId,
      fromType: fromType,
    }
    return await this.http.post<Entity[]>(environment.backendControllerUrl + "/Entity/GetAllChildrenByEntityId", body).toPromise();
  }

  /**
   * API that return entities of a specified type
   * @param type string, this param is used on retrieving the entities of this type
   * */
  public getEntitiesUsingType(type: string): Observable<Entity[]> {
    const body = {
      entityType: type
    }
    return this.http.post<Entity[]>(environment.backendControllerUrl + "/Entity/GetEntityByType", body);
  }

  public getEntitiesForSearch(toType:string ,returnClosed :boolean,id:number, searchValue?:string ): Observable<Entity[]> {
    const body = {
      parentId: id,
      toType: toType,
      toDate: this.selectedDate,
      returnClosed :returnClosed,
      searchValue : searchValue
    }
    return this.http.post<Entity[]>(environment.backendControllerUrl + "/Entity/GetEntitiesForSearch", body);
  }

  public getAllEntitiesForSearch(toType:string , returnClosed :boolean, searchValue?:string): Observable<Entity[]> {
    const body = {
      parentId: -1,
      toType: toType,
      toDate: this.selectedDate,
      returnClosed:returnClosed,
      searchValue : searchValue
    }
    return this.http.post<Entity[]>(environment.backendControllerUrl + "/Entity/GetEntitiesForSearch", body);
  }
  /**
   * API that returns entities based on a hierarchy, from a type that is higher and to type which is on a lower level hierarchy
   * @param fromType string, this parameter sets the higher type level in hierarchy
   * @param toType string, this parameter sets the lower type level in hierarchy
   * @param body contains properties linked to the type entity that should be brought in the request
   * */
  public getEntitiesByType(
    toType: string,
    fromType: string,
    body?: any
  ): Observable<Entity[]> {

    if (body != null)
      body = JSON.stringify(body)

    const bodyParameters = {
      fromType: fromType,
      toType: toType,
      scenarioId: this.scenarioId,
      toDate: this.selectedDate,
      filters: body
    };

    if (body != null) {
      return this.http
        .post<Entity[]>(environment.backendControllerUrl + "/Entity/GetEntitiesByTypeWithFilters", bodyParameters)
        .pipe(take(1));
    }
    return this.http
      .post<Entity[]>(environment.backendControllerUrl + "/Entity/GetEntitiesByTypeByScenarioIdByFromType", bodyParameters)
      .pipe(take(1));
  }

  /**
   * API that returns properties of an entity type
   * @param entityType string, type of the entity
   * */
  public getPropertiesByType(entityType: string): Observable<EntityProperty[]> {
    const body = {
      entityType: entityType
    }
    return this.http.post<EntityProperty[]>(environment.backendControllerUrl + "/Property/GetPropertiesByType", body)
      .pipe(shareReplay());
  }

  /**
   * API that returns all properties that are linked with the entity types placed as parameters to the api
   * @param types EntityType[], specify entity types
   * */
  public getAllTypesAndTheirProperties(types: EntityType[]): Observable<EntityType[]> {
    return this.http.post<EntityType[]>(
      environment.backendControllerUrl +
      "/Property/GetAllTypesAndTheirProperties",
      types
    );
  }

  /**
   * API used for getting the type definition to place the type of a property text/date/number/decimal numbers
   * */
  public getTypeDefinition(): Observable<TypeDefinition[]> {
    return this.http.get<TypeDefinition[]>(environment.backendControllerUrl + "/TypeDefinition/GetTypeDefinitions");
  }

  //Methods used for posting on editing page
  private postValues(url, value) {
    const headers = this.headers;
    return this.http.post(url, value, {
      headers,
    });
  }

  /**
   * API used for creating a new property/edit an existing property
   * @param property EntityProperty, details of the new property to be created/old one to be updated
   * */
  public postProperty(property: EntityProperty) {
    return this.postValues(
      environment.backendControllerUrl + "/Property/PostProperty",
      property
    );
  }

  /**
   * API used for creating a new entity
   * @param body Entity, the details of the new entity/existing one
   * */
  public postEntity(body: Entity): Observable<Entity> {
    return this.http.post<Entity>(
      environment.backendControllerUrl + "/Entity/CreateEntity",
      body
    );
  }

  /**
   * API used updating an entity
   * @param body Entity, the details of the new entity/existing one
   * */
  public updateEntity(body: Entity): Observable<Entity> {
    return this.http.post<Entity>(
      environment.backendControllerUrl + "/Entity/UpdateEntity",
      body
    );
  }

  /**
  * API used updating multiple entities
  * @param body array of the entitites to be updated
  * */
  public updateEntities(body: Entity[]) : Observable<Entity[]> {
    return this.http.post<Entity[]>(
      environment.backendControllerUrl + "/Entity/UpdatetMultipleEntities",
      body
    );
  }

  public logEntityChanges(body: ChangeLocation) {
    return this.http.post(
      environment.backendControllerUrl + "/Entity/LogChangeLocation",
      body
    );
  }

  /**
   * API used for creating/editing an entity type
   * @param entityTypesToPost EntityTypesToPost, this param contains the lower entity types to edit their index and the new entity to be created/editted
   * */
  public postEntityType(
    entityTypesToPost: EntityTypesToPost
  ): Observable<EntityTypesToPost> {
    const url = environment.backendControllerUrl + "/EntityType/PostEntityType";
    const headers = new HttpHeaders().set("Content-Type", "application/json");
    return this.http
      .post<EntityTypesToPost>(url, entityTypesToPost, { headers })
      .pipe(take(1));
  }

  /**
   * API used for linking properties to an entity type
   * @param entityTypeProperties The array is containing id of the property and the id of the entity type from DB
   * */
  public postEntityTypeProperties(entityTypeProperties: EntityTypeProperty[]) {
    return this.http
      .post(
        environment.backendControllerUrl +
        "/EntityTypeProperty/PostEntityTypeProperties",
        entityTypeProperties
      )
      .pipe(take(1));
  }

  /**
   * API used for supporting functionality of opening a closed entity
   * @param entityIds The id's of the entities to be open
   * */
  public openEntity(entitiesInfo: EntityInfo[]): Observable<void> {
    return this.http.post<void>(
      environment.backendControllerUrl + "/Entity/OpenEntity",
      entitiesInfo
    );
  }

  /**
   * API used for supporting functionality of closing an open entity
   * @param entityIds The id's of the entities to be closed
   * */
  public closeEntity(entitiesInfo: EntityInfo[]): Observable<void> {
    return this.http.post<void>(
      environment.backendControllerUrl + "/Entity/CloseEntity",
      entitiesInfo
    );
  }

  /**
   * API used for deleting a property knowing the id of the prop
   * @param propertyId The id of the property that is going to be deleted
   * */
  public deleteProperty(propertyId: number) {
    return this.http.delete(
      environment.backendControllerUrl +
      "/Property/DeleteProperty/?id=" +
      propertyId.toString()
    );
  }

  /**
   * API that checks if an EntityType is being used in the program
   * @param entityId The id of the entity to be checked if it's in use
   * */
  public isEntityTypeInUse(entityId: number): Promise<boolean> {
    return this.http
      .post<boolean>(
        environment.backendControllerUrl + "/EntityType/IsEntityTypeInUse",
        entityId
      )
      .toPromise();
  }

  /**
  * API that checks if a property is being used in the program
  * @param propertyId The id of the property to be checked if it's in use
  * */
  public isPropertyInUse(propertyId: number): Promise<boolean> {
    return this.http
      .post<boolean>(
        environment.backendControllerUrl +
        "/EntityTypeProperty/IsPropertyInUse",
        propertyId
      )
      .toPromise();
  }
  /**
   * API used for deleting multiple entity types
   * @param ids The id's of the entity types that are wanted to be deleted
   * */
  public deleteEntityType(entityTypesIds: number[]) {
    return this.http.delete(
      environment.backendControllerUrl + "/EntityType/DeleteEntityType",
      { body: entityTypesIds }
    );
  }
  /**
   * API used for deleting multiple entities
   * @param entityIds The id's of the entites to be deleted
   * */
  public deleteEntities(entitiesInfo: EntityInfo[]) {
    return this.http.post(
      environment.backendControllerUrl + "/Entity/DeleteEntity",
      entitiesInfo
    );
  }

  /**
   * API used for deleting multiple linked properties of an entity type, dictionary -> (entityTypeId, entityTypePropertiesId's)
   * */
  public deleteEntityTypeProperties(propertiesToDelete: EntityTypePropertiesDictionaryWrapper): Observable<void> {
    return this.http
      .post<void>(
        environment.backendControllerUrl + "/EntityTypeProperty/DeleteEntityTypeProperties",
        propertiesToDelete
      )
      .pipe(take(1));
  }

  /**
   * API used for deleting properties that are linked to an entity type
   * @param entityTypeProperties The entity type properties that contains the id's of the entity type and the property
   * */
  public deleteEntityTypeProperty(entityTypeProperties: EntityTypeProperty[]) {
    return this.http
      .delete(
        environment.backendControllerUrl +
        "/EntityTypeProperty/DeleteEntityTypeProperty",
        { body: entityTypeProperties }
      )
      .pipe(take(1));
  }
}
