// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .info-popup .pp-main-popup-content.pp-info {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
::ng-deep .info-popup .popup-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
::ng-deep .info-popup .popup-title p {
  font-size: 18px;
  text-transform: none;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.009px;
}
::ng-deep .info-popup .popup-title i {
  color: #fff;
  cursor: pointer;
}
::ng-deep .info-popup .pp-main-popup-content {
  padding: 36px 36px !important;
}
::ng-deep .info-popup .dx-popup-content {
  padding-right: 0;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
}
::ng-deep .info-popup .pp-bottom-popup-buttons {
  height: 50px;
  box-sizing: border-box;
  border-top: 1px solid rgb(210, 212, 212);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 24px;
}
::ng-deep .info-popup .pp-bottom-popup-buttons {
  margin-top: auto;
}
::ng-deep .info-popup .pp-bottom-popup-buttons dx-button {
  margin-left: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/Modals/report-popup-info/report-popup-info.component.scss"],"names":[],"mappings":"AAEI;EACE,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AADN;AAII;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AAFN;AAIM;EACE,eAAA;EACA,oBAAA;EACA,gBAAA;EACA,WAAA;EACA,uBAAA;AAFR;AAKM;EACE,WAAA;EACA,eAAA;AAHR;AAOI;EACE,6BAAA;AALN;AAQI;EACE,gBAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;AANN;AASI;EACE,YAAA;EACA,sBAAA;EACA,wCAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,mBAAA;AAPN;AAUI;EACE,gBAAA;AARN;AAWI;EACE,gBAAA;AATN","sourcesContent":["::ng-deep {\r\n  .info-popup {\r\n    .pp-main-popup-content.pp-info {\r\n      width: 100%;\r\n      display: flex;\r\n      justify-content: center;\r\n      align-items: center;\r\n    }\r\n\r\n    .popup-title {\r\n      display: flex;\r\n      justify-content: space-between;\r\n      align-items: center;\r\n\r\n      p {\r\n        font-size: 18px;\r\n        text-transform: none;\r\n        font-weight: 600;\r\n        color: #fff;\r\n        letter-spacing: 0.009px;\r\n      }\r\n\r\n      i {\r\n        color: #fff;\r\n        cursor: pointer;\r\n      }\r\n    }\r\n\r\n    .pp-main-popup-content {\r\n      padding: 36px 36px !important;\r\n    }\r\n\r\n    .dx-popup-content {\r\n      padding-right: 0;\r\n      padding-left: 0;\r\n      padding-top: 0;\r\n      padding-bottom: 0;\r\n    }\r\n\r\n    .pp-bottom-popup-buttons {\r\n      height: 50px;\r\n      box-sizing: border-box;\r\n      border-top: 1px solid rgba(210, 212, 212, 1);\r\n      display: flex;\r\n      align-items: center;\r\n      justify-content: flex-end;\r\n      padding-right: 24px;\r\n    }\r\n\r\n    .pp-bottom-popup-buttons {\r\n      margin-top: auto;\r\n    }\r\n\r\n    .pp-bottom-popup-buttons dx-button {\r\n      margin-left: 8px;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
