import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CustomersService {

  constructor(private http: HttpClient) { }

  public getLogLevelSettings(): Observable<any> {
    return this.http.get<any>(
      window.location.origin + "/api/Customers/GetLogLevelSettings"
    )
  }
}
