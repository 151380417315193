import { TranslateService } from "@ngx-translate/core";
import { ScenarioService } from "src/app/Services/scenario.service";
import { ReportService } from "../../Services/report.service";
import { DataHandlerService } from "../../Services/data-handler.service";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ShareService } from "../../Services/share.service";
import { EntityInfo } from "../../Models/entityInfo";
import { ReportSignalRService } from "src/app/Services/signalR/report-signal-r.service";
import { environment } from "src/environments/environment";
import { Subscription } from "rxjs";

@Component({
  selector: "app-confirm-delete-popup",
  templateUrl: "./confirm-delete-popup.component.html",
  styleUrls: ["./confirm-delete-popup.component.scss"],
})
export class ConfirmDeletePopupComponent implements OnInit, OnDestroy {

  private includedDisableDeleteButtonModes: string[] = ["P", "R", "ET"];

  public subscriptions: Subscription = new Subscription();
  public mode: string;
  public deletePopupVisible: boolean = false;
  public text: string = this.translate.instant("WouldYouLikeToDeleteSelectedItems");
  public drawings: string[];
  public selectedItemsNames: string[];
  public selectedIds: number[];
  public isButtonDisabled: boolean = false;
  public isDeleteButtonDisabled: boolean = false;
  public closingOnOutsideClick: boolean = true;
  public showCloseButton: boolean = true;
  public entitiesInfo: EntityInfo[];

  constructor(
    private dataHandler: DataHandlerService,
    private reportService: ReportService,
    private scenarioServise: ScenarioService,
    private share: ShareService,
    private reportSignalRService: ReportSignalRService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.subscribeToReportEvents();
  }

  public closeDeletePopup(): void {
    this.deletePopupVisible = false;
  }

  public closeAndDelete(): void {
    this.isButtonDisabled = true;
    this.isDeleteButtonDisabled = true;
    switch (this.mode) {
      case "E":
        this.closingOnOutsideClick = false;
        this.showCloseButton = false;
        this.dataHandler.deleteEntities(this.entitiesInfo).subscribe(
          () => this.share.upgradeGrid(),
          () => { },
          () => this.afterDeletingAnEntity()
        );

        break;
      case "P":
        this.selectedIds.forEach((id) => {
          this.dataHandler.deleteProperty(id).subscribe(
            () => this.share.upgradeGrid(),
            () => { },
            () => this.afterDelete()
          );
        });
        break;

      case "ET":
        this.dataHandler.deleteEntityType(this.selectedIds).subscribe(
          () => {
            this.share.upgradeGrid();
          },
          (error) =>
            console.log("HTTP Error during deliting entity types: ", error),
          () => this.afterDelete()
        );

        break;
      case "R":
        this.reportService.deleteReport(this.selectedIds).subscribe(
          () => this.share.upgradeGrid(),
          () => { },
          () => {
            this.afterDelete();
            this.reportSignalRService.invokeDeletedReport(this.selectedIds);
          }
        );

        break;
      case "S":
        this.scenarioServise.deleteScenarios(this.selectedIds).subscribe(
          () => this.share.upgradeGrid(),
          () => { },
          () => this.afterDelete()
        );

        break;
    }
  }

  public trackByFn(drawing): any {
    return drawing;
  }

  /// Method used to check whether the mode is included in the list of modes adapted for disabling delete button.
  public isModeIncluded(mode: string): boolean {
    if (mode) {
      let isAtLeastOneMode: boolean = this.includedDisableDeleteButtonModes.some(s => s.includes(mode));
      return isAtLeastOneMode;
    }
    return false;
  }

  private afterDeletingAnEntity() {
    this.share.deletePopupEmitter.emit();
    this.deletePopupVisible = false;
    this.closingOnOutsideClick = true;
    this.showCloseButton = true;
  }

  private afterDelete(): void {
    this.share.deletePopupEmitter.emit();
    this.deletePopupVisible = false;
    this.closingOnOutsideClick = true;
    this.showCloseButton = true;
    this.share.emptySearchInput.emit();
  }

  /**
   * Subscribes to the requires report events in this case creation/edit started and finished.
   * Also adds the subscriptions to the Subscription object.
   */
  private subscribeToReportEvents(): void {
    this.subscriptions.add(
      this.share.onReportCreationOrEdit.subscribe(() => {
        this.handleReportCreationOrEdit();
      })
    );

    this.subscriptions.add(
      this.share.onReportCreationOrEditFinished.subscribe(() => {
        this.handleReportCreationOrEditFinished();
      })
    );

    this.subscriptions.add(
      this.share.onReportsDeletedFinished.subscribe((deletedIds) => {
        if (this.mode == "R") {
          this.closePopupInCaseSelectedReportsGotDeleted(deletedIds);
        }
      })
    );
  }

  /**
   * Handles the delete button availability based on the report creation started.
   */
  private handleReportCreationOrEdit(): void {
    if (this.isModeIncluded(this.mode)) {
      this.isDeleteButtonDisabled = true;
    }
  }

  /**
   * Handles the delete button availability based on the report creation finished.
   */
  private handleReportCreationOrEditFinished(): void {
    this.reportService.reportCreationOrEditOngoing(environment.customer).subscribe((isOngoing) => {
      if (this.isModeIncluded(this.mode)) {
        this.isDeleteButtonDisabled = isOngoing;
      }
    });
  }

  /**
   * Method used to close the delete popup in case the report got deleted from another user.
   * @param deletedIds - ids of the reports that got deleted.
   */
  private closePopupInCaseSelectedReportsGotDeleted(deletedIds: number[]): void {
    try {
      const hasDeletedId: boolean = deletedIds.some(id => this.selectedIds.includes(id));
      if (hasDeletedId) {
        this.closeDeletePopup();
      }
    } catch (exception) {
      console.log("Error during closing the popup after deleting reports: ", exception);
      this.closeDeletePopup();
    }
  }

  /**
   * Unsubscribes from all the subscriptions aquired during the lifecycle.
   */
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
