import { Directive, Input } from "@angular/core";
import { FormControl, NG_VALIDATORS, Validator } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { EntityType } from "../Models/entityType";


@Directive({
  selector: "[uniqueEntityType]",
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: UniqueEntityTypeValidatorDirective,
      multi: true,
    },
  ],
})  
export class UniqueEntityTypeValidatorDirective implements Validator {
  @Input() dataToCheckForUniqueName: EntityType[];
  @Input() initialName: string;

  constructor(public translate: TranslateService) { }

  validate(formControl: FormControl) {
    let isValueUnique: boolean;
    if (formControl.value !== "" && formControl.value !== null) {
      if (this.initialName && this.dataToCheckForUniqueName) {
        const filteredData = this.dataToCheckForUniqueName.filter(
          (item) => item.Name.toLowerCase() !== this.initialName.toLowerCase()
        );

        isValueUnique = !filteredData.find(
          (item) => this.translate.instant(item.Name).toLowerCase() === this.translate.instant(formControl.value).toLowerCase()
        );
      } else
        isValueUnique = !this.dataToCheckForUniqueName?.find(
          (item) => this.translate.instant(item.Name).toLowerCase() === this.translate.instant(formControl.value).toLowerCase()
        );

      if (!isValueUnique) return { isNotUnique: true };
    }

    return null;
  }
}
