import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalR"
import { environment } from 'src/environments/environment';
import { LockingElementService } from '../locking-element.service';
import { Observable } from 'rxjs';
import { NotifyRefreshReportsService } from '../utility/notify-refresh-reports.service';
import { TranslateService } from '@ngx-translate/core';
import { ShareService } from '../share.service';
import { SignalrActionsService } from './signalr-actions.service';

@Injectable({
  providedIn: 'root'
})
export class ReportSignalRService {
  private currentlyEditingReportId: string = "-1";
  private validPaths: string[] = ["editor/entity-type", "reports", "editor/type-property"];
  private reportPath: string[] = ["reports"];

  private isCurrentlyCreatingAReport: boolean = false;

  constructor(private _elementLockingService: LockingElementService,
    private _notifyRefreshReportService: NotifyRefreshReportsService,
    private _translateService: TranslateService,
    private _shareService: ShareService,
    private _signalRActions: SignalrActionsService) {

    this._hubConnection = new signalR.HubConnectionBuilder()
      .withUrl('/reportHub')
      .withAutomaticReconnect()
      .build();
  }

  private _hubConnection: signalR.HubConnection

  /* istanbul ignore next */
  public startConnection(): Observable<void> {
    return new Observable<void>(observer => {
      this._hubConnection.start()
        .then(() => {
          console.log('SignalR connection started');
          observer.next();
          observer.complete();
          window.addEventListener('beforeunload', () => {
            this.invokeDisconnectingEvent();
          });
        })
        .catch(err => {
          console.error('Error while starting SignalR connection: ', err);
          observer.error(err);
        });
    });
  }
  public onCloseListener() {
    const tryReconnect = () => {
      console.log("Attempting reconnect...");
      this.startConnection().subscribe({
        next: () => {
          console.log("Reconnection succsessful.");
        },
        error: (err: any) => {
          setTimeout(tryReconnect, 3000);
        }
      });
    };
  }

  ///#listeners
  /// Listeners cannot be tested with UnitTests, they can be tested with selenium tests only.
  /// Yet we will test with unit tests the functionality of the listener itself.
  /* istanbul ignore next */
  public reportCreationListener(): void {
    this._hubConnection.on('CreatedReport', (customer) => {
      if (customer === environment.customer && this.shouldInvokeMessage(this.validPaths)) {
        this._signalRActions.reportCreated(this._notifyRefreshReportService,
          this._shareService, this._translateService);
      }
    });
  }

  /* istanbul ignore next */
  public reportCreationCanceledListener(): void {
    this._hubConnection.on("CanceledCreatingNewReport", (customer) => {
      if (customer === environment.customer && this.shouldInvokeMessage(this.validPaths)) {
        this._signalRActions.reportCreateCanceled(this._notifyRefreshReportService,
          this._shareService, this._translateService);
      }
    });
  }

  /* istanbul ignore next */
  public reportPopupOpened(): void {
    this._hubConnection.on("ReportPopupOpened", (customer: string) => {
      if (customer == environment.customer && this.shouldInvokeMessage(this.validPaths)) {
        this._signalRActions.reportPopupOpen(this._shareService);
      }
    });
  }

  /// Listener used to apply the lock statement on report that is being edited.
  /* istanbul ignore next */
  public reportBeganEditListener(): void {
    this._hubConnection.on('BeganEditingReport', (report: any, customer: string) => {
      if (customer === environment.customer && this.shouldInvokeMessage(this.validPaths)) {
        this._signalRActions.reportEditStarted(this._notifyRefreshReportService,
          this._shareService, this._translateService, this._elementLockingService, report.id.toString());
      }
    });
  }

  /* istanbul ignore next */
  public reportBeganCreationListener(): void {
    this._hubConnection.on("BeganCreatingReport", (customer: string, report: any) => {
      if (customer === environment.customer && this.shouldInvokeMessage(this.validPaths)) {
        this._signalRActions.reportCreateStarted(
          this._notifyRefreshReportService, this._shareService, this._translateService
        );
      }
    });
  }

  /// Listener used to apply unlock statement on report that was edited.
  /* istanbul ignore next */
  public reportFinishedEditListener(): void {
    this._hubConnection.on('FinishedEditingReport', (report: any, customer: string) => {
      if (customer === environment.customer && this.shouldInvokeMessage(this.validPaths)) {
        this._signalRActions.reportEditDone(this._notifyRefreshReportService,
          this._shareService, this._translateService, this._elementLockingService, report.id.toString());
      }
    });
  }

  /* istanbul ignore next */
  private reportCanceledEditListener(): void {
    this._hubConnection.on("CancelEditingReport", (report: any, customer: string) => {
      if (customer === environment.customer && this.shouldInvokeMessage(this.validPaths)) {
        this._signalRActions.reportEditAbandon(this._notifyRefreshReportService,
          this._shareService, this._translateService, this._elementLockingService, report.id.toString());
      }
    });
  }

  /* istanbul ignore next */
  private forceReportCancelationListener(): void {
    this._hubConnection.on("ForceCancelEditReport", (customer: string) => {
      if (customer === environment.customer && this.shouldInvokeMessage(this.validPaths)) {
        this._signalRActions.forcefullEditCancel(this._notifyRefreshReportService,
          this._shareService, this._translateService);
      }
    });
  }

  /* istanbul ignore next */
  private forceReportCreationCanceledListener(): void {
    this._hubConnection.on("ForceCancelCreateNewReport", (customer: string) => {
      if (customer == environment.customer && this.shouldInvokeMessage(this.validPaths)) {
        this._signalRActions.forcefullCreationCancel(this._notifyRefreshReportService,
          this._shareService, this._translateService);
      }
    })
  }

  /* istanbul ignore next */
  private reportDeleteListener(): void {
    this._hubConnection.on("DeletedReports", (customer) => {
      if (customer === environment.customer && this.shouldInvokeMessage(this.reportPath)) {
        this._signalRActions.reportDeleted(this._notifyRefreshReportService,
          this._shareService, this._translateService);
      }
    });
  }

  private unlockedAllReportsListener(): void {
    this._hubConnection.on("ReportsUnlocked", (customer) => {
      if (customer === environment.customer && this.shouldInvokeMessage(this.validPaths)) {
        this._signalRActions.unlockEverything(this._notifyRefreshReportService,
          this._shareService, this._translateService);
      }
    });
  }

  ///#invokers

  public invokeBeginEditingReport(customer: string, report: any): void {
    this._hubConnection.invoke("InvokeBeginEditing", customer, report).catch((error) => console.log(error));
    this.currentlyEditingReportId = report.Id.toString();
    this._elementLockingService.lockElement(report.Id.toString());
  }

  public invokeBeginCreatingReport(customer: string, report: any): void {
    this._hubConnection.invoke("InvokeBeginCreating", customer, report).catch((error) => console.log(error));
    this.isCurrentlyCreatingAReport = true;
  }

  public invokeCancelEditingReport(customer: string, report: any): void {
    if (report.Id && (report.Id.toString() === this.currentlyEditingReportId)) {
      this._hubConnection.invoke("InvokeCancelEditingReport", customer, report).catch((error) => console.log(error));
      this.currentlyEditingReportId = "-1";
      this._elementLockingService.unlockElementOnCurrentUser(report.Id.toString());
    } else {
      /// Mention that the new report popup is closed.
      this.invokeCanceledCreationOfNewReport();
    }
  }

  public invokeSaveEditingReport(customer: string, report: any): void {
    this._hubConnection.invoke("InvokeSaveEditingReport", customer, report).catch((error) => console.log(error));
    this.currentlyEditingReportId = "-1";
    this._elementLockingService.unlockElementOnCurrentUser(report.Id.toString());
  }

  public invokeCreatedNewReport(): void {
    this._hubConnection.invoke("InvokeCreateNewReport", environment.customer).catch((error) => console.log(error));
    this.isCurrentlyCreatingAReport = false;
  }

  public invokeCanceledCreationOfNewReport(): void {
    this._hubConnection.invoke("InvokeCanceledCreationOnNewReport", environment.customer).catch((error) => console.log(error));
    this.isCurrentlyCreatingAReport = false;
  }

  public invokeOpenedReportPopUp(): void {
    this._hubConnection.invoke("InvokeOpenedReportPopup", environment.customer).catch((error) => console.log(error));
  }

  public invokeDeletedReport(): void {
    this._hubConnection.invoke("InvokeDeleteReport", environment.customer).catch((error) => console.log(error));
  }

  public invokeDisconnectingEvent(): void {
    this._hubConnection.invoke("ClientDisconnected", environment.customer, this.currentlyEditingReportId, this.isCurrentlyCreatingAReport).catch((error) => console.log(error));
  }

  public invokeUnlockedAllReports(): void {
    this._hubConnection.invoke("InvokeUnlockedAllReports", environment.customer).catch((error) => console.error(error.message));
  }

  public subscribeToListeners(): void {
    this.onCloseListener();
    /// TODO: if logic is similar than use just one listener instead of all 3. (delete should have a different listener because you can delete many).
    this.reportCreationListener();
    this.reportDeleteListener();
    this.reportBeganEditListener();
    this.reportBeganCreationListener();
    this.reportFinishedEditListener();
    this.reportCanceledEditListener();
    this.forceReportCancelationListener();
    this.reportPopupOpened();
    this.reportCreationCanceledListener();
    this.forceReportCreationCanceledListener();
    this.unlockedAllReportsListener();
  }

  public unsubscribeTolisteners(): void {
    this._hubConnection.off("DeletedReports");
    this._hubConnection.off("FinishedEditingReport");
    this._hubConnection.off("BeganEditingReport");
    this._hubConnection.off("CreatedReport");
  }

  public stopConnection(): void {
    this._hubConnection.stop();
    console.log('SignalR connection stopped');
  }

  /// Method used to check if the message from signalR should be printed or not.
  private shouldInvokeMessage(paths: string[]): boolean {
    var path: string = this.getLocationPath();
    let resultedPath: string = path.replace(environment.customer, "");
    if (resultedPath) {
      let includesAtLeastOnePath: boolean = paths.some(s => resultedPath.includes(s));
      return includesAtLeastOnePath;
    }
    return false;
  }

  public getLocationPath(): string {
    return window.location.pathname;
  }
}
