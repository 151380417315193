import { Entity } from "src/app/Models/entity";
import { Injectable } from "@angular/core";
import { EventEmitter } from "@angular/core";
import { DxDataGridComponent } from "devextreme-angular";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { EntityTypeProperty } from "../Models/entityTypeProps";
import { PropertyByQuadrant } from "../Models/propertyByQuadrant";
import { EntityType } from "../Models/entityType";
import { DataForEditOrDeleteSelectedEntity } from "../Models/dataForEditOrDeleteSelectedEntity";
import { Subject } from "rxjs";
import { DataForLastEntityTreeView } from "../Models/info-last-selected-item";


@Injectable({
  providedIn: "root",
})
export class ShareService {
  private deleteEntitySubject = new Subject<void>();
  private entityTypePopUpClosed = new Subject<void>();

  popupClosed$ = this.entityTypePopUpClosed.asObservable();

  public selectedMode: string;
  public chartMode: boolean;
  public template: string;
  public selectedDate: string;

  public reportIdFromUrl: number;
  public onEntityTypesWithChildrenRecieved: EventEmitter<Entity[]> = new EventEmitter();
  public onReportsDeletedFinished: EventEmitter<number[]> = new EventEmitter();
  public onReportDataLoadingStarted: EventEmitter<void> = new EventEmitter();
  public onReportDataLoadingFinished: EventEmitter<void> = new EventEmitter();
  public onReportInfoPopupClosed: EventEmitter<void> = new EventEmitter();
  public onReportCreationStatusChanged: EventEmitter<boolean> = new EventEmitter();
  public onReportsDataRequiresUpdate: EventEmitter<void> = new EventEmitter();
  public onClientForceDisconnect: EventEmitter<void> = new EventEmitter();
  public onReportCreationOrEdit: EventEmitter<void> = new EventEmitter();
  public onReportCreationOrEditFinished: EventEmitter<void> = new EventEmitter();
  public onUnlockFromAdministrator: EventEmitter<void> = new EventEmitter();
  public onNewEnabledFromAdministrator: EventEmitter<void> = new EventEmitter();
  public onUnlockedCurrentElementFinished: EventEmitter<string> = new EventEmitter();
  public onEntityOpenedOrClosed: EventEmitter<void> = new EventEmitter();
  public recievedColumnsEmitter: EventEmitter<any> = new EventEmitter();
  public onEntitySelected: EventEmitter<Entity> = new EventEmitter();
  public onEntitySelectedTreeviewReference: EventEmitter<Entity> = new EventEmitter();
  public recievedChartMode: EventEmitter<boolean> = new EventEmitter();
  public openEditPopup: EventEmitter<DataForEditOrDeleteSelectedEntity> = new EventEmitter();
  public onLanguageChanged: EventEmitter<string> = new EventEmitter();
  public receivedDataGridEmitter: EventEmitter<DxDataGridComponent> = new EventEmitter();
  public recievedDateEmitter: EventEmitter<string> = new EventEmitter();
  public onScenarioChanged: EventEmitter<number> = new EventEmitter();
  public dropDownButtonChanged: EventEmitter<string> = new EventEmitter();
  public deletePopupEmitter: EventEmitter<any> = new EventEmitter();
  public onAddModeChanged: EventEmitter<boolean> = new EventEmitter();
  public onIsForReconustrionChanged: EventEmitter<boolean> = new EventEmitter();
  public onShowClosedEntitiesChanged: EventEmitter<boolean> = new EventEmitter();
  public onReportIdPassed: EventEmitter<number> = new EventEmitter();
  public onNewEntityCreated: EventEmitter<Entity> = new EventEmitter();
  public onToggleButtons: EventEmitter<void> = new EventEmitter();
  public onEntityTypeIconChanged: EventEmitter<EntityType> = new EventEmitter();
  public lastEntityTreeView: EventEmitter<DataForLastEntityTreeView> = new EventEmitter();
  public editOrDeleteEntityWithNoChildren: EventEmitter<boolean> = new EventEmitter();
  public pivotFieldsEmmiter: EventEmitter<
    {
      dataField: string;
      area: string;
      summaryType?: string;
      displayFolder?: string;
      dataType?: string;
      format?: {
        type: string;
        precision: number;
      };
    }[]
  > = new EventEmitter();
  public onPivotGridSourceLoaded: EventEmitter<any> = new EventEmitter();
  public onSpaceManagementGridsRowDeleted: EventEmitter<any> = new EventEmitter();
  public visibilityEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  public upgradeGridEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  //Used in edit drag&drop component
  public receivedDragDropEmitter: EventEmitter<any> = new EventEmitter();
  public onPropertyByQuadrantChanged: EventEmitter<PropertyByQuadrant[]> = new EventEmitter();
  private propertyUpdate = new BehaviorSubject({});
  public currentEntityTypePropToUpdate = this.propertyUpdate.asObservable();
  public emptySearchInput: EventEmitter<any> = new EventEmitter();
  shareServiceMock: any;
  public undoActionTriggeredWhileSearchActive: EventEmitter<void> = new EventEmitter();
  public onClearSearchActionsPerformed: EventEmitter<void> = new EventEmitter();

  public upgradeGrid(): void {
    this.upgradeGridEmitter.emit(true);
  }

  public changeProperty(message: EntityTypeProperty): void {
    this.propertyUpdate.next(message);
  }

  public sendVisibility(): void {
    this.visibilityEmitter.emit(false);
  }

  public sendChartMode(): void {
    this.recievedChartMode.emit(this.chartMode);
  }

  public entityPopupClosed(): void {
    this.entityTypePopUpClosed.next();
  }

  public emitReportCreationOrEdit(): void {
    this.onReportCreationOrEdit.emit();
  }

  public emitReportCreationOrEditFinished(): void {
    this.onReportCreationOrEditFinished.emit();
  }

  public emitCurrentlyUnlockedElementFinished(id: string): void {
    this.onUnlockedCurrentElementFinished.emit(id);
  }

  public emitClientForcefullyDisconnected(): void {
    this.onClientForceDisconnect.emit();
  }

  public emitReportDataUpdate(): void {
    this.onReportsDataRequiresUpdate.emit();
  }

  public emitReportCreationStatusChange(value: boolean): void {
    this.onReportCreationStatusChanged.emit(value);
  }

  public emitReportInfoPopupClosed(): void {
    this.onReportInfoPopupClosed.emit();
  }

  public emitEntitiesWithChildrenRecieved(entities: Entity[]): void {
    this.onEntityTypesWithChildrenRecieved.emit(entities);
  }

  public emitReportUnlockFromAdmin(): void {
    this.onUnlockFromAdministrator.emit();
  }

  public emitNewEnabledFromAdministrator(): void {
    this.onNewEnabledFromAdministrator.emit();
  }

  public emitDataReportLoadingStarted(): void {
    this.onReportDataLoadingStarted.emit();
  }

  public emitDataReportLoadingFinished(): void {
    this.onReportDataLoadingFinished.emit();
  }

  public emitReportsDeletedFinished(ids: number[]): void {
    this.onReportsDeletedFinished.emit(ids);
  }

  public emitUndoActionTriggeredWhileSearchActive(): void {
    this.undoActionTriggeredWhileSearchActive.emit();
  }

  public emitOnClearSearchActionsPerformed(): void {
    this.onClearSearchActionsPerformed.emit();
  }
}
